/* line 52, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__details, .prop-description__title {
	margin-bottom: 20px;
}

/* line 57, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__label {
	text-transform: uppercase;
}

/* line 61, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__value {
	color: rgb(var(--color-global-negative));
}

/* line 65, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__list-item {
	margin-bottom: 10px;
}

/* line 69, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__amenities {
	margin-bottom: 40px;
}

/* line 73, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__amenities-list {
	background-color: rgb(var(--color-global-widget));
	padding: 20px;
}

/* line 78, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__amenities-list-item-text {
	padding-left: 20px;
	display: block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .prop-description__amenities-list-item-text {
	padding-left: initial;
	padding-right: 20px;
}

/* line 88, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__amenities-list-item-icon, .prop-description__features-list-item-icon {
	position: absolute;
	padding-top: 1px;
}

/* line 98, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__amenities-list-item-icon {
	color: rgb(var(--color-global-secondary));
}

/* line 102, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__features-list-item-icon {
	color: rgb(var(--color-global-secondary));
}

/* line 106, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__features-list-item-text {
	padding-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .prop-description__features-list-item-text {
	padding-left: initial;
	padding-right: 20px;
}

/* line 114, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__features-list {
	background-color: rgb(var(--color-global-widget));
	padding: 20px;
}

@media only screen and (min-width: 120em) {
	/* line 119, scss/80-themes/Saunders/60-components/property-description.scss */
	.prop-description__comments-long {
		column-count: 1;
		column-gap: 20px;
		column-rule-color: rgb(var(--color-global-border));
		column-rule-width: 0;
		column-rule-style: solid;
	}
}

/* line 132, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__comments-long p {
	margin-bottom: 10px;
}

/* line 136, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__comments-long p:last-child {
	margin-bottom: 0;
}

/* line 141, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__more-properties {
	font-size: 0.85em;
	font-weight: bold;
	background-color: rgb(var(--color-global-primary));
	padding: 5px;
	margin: 0 0 20px;
}

/* line 148, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__more-properties a {
	color: rgb(var(--color-global-positive));
	line-height: normal;
}

/* line 152, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__more-properties a:hover {
	color: rgb(var(--color-global-positive));
}

/* line 158, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__expandable {
	margin-top: 20px;
}

/* line 162, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__expandable .prop-description__title {
	padding: 10px 0;
	border-bottom: 1px solid rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-header));
	font-size: 26px;
	cursor: pointer;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 4.3px;
	margin-bottom: 5px;
}

/* line 173, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__expandable .prop-description__title .icon {
	color: rgb(var(--color-global-highlight));
	padding-right: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 162, scss/80-themes/Saunders/60-components/property-description.scss */
	.prop-description__expandable .prop-description__title {
		padding: 18px 0;
		font-size: 20px;
	}
}

/* line 184, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__expandable .prop-description__value {
	color: rgb(var(--color-global-text));
	font-size: 16px;
}

/* line 189, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__expandable .prop-description__label {
	font-weight: 700;
	font-size: 14px;
}

/* line 194, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__expandable .prop-description__list-item {
	margin-bottom: 20px;
}

/* line 199, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__expandable .is-expanded .icon {
	transform: scaleY(-1);
}

/* line 203, scss/80-themes/Saunders/60-components/property-description.scss */
.prop-description__expandable .box-expandable__content {
	margin-top: 20px;
}

/*# sourceMappingURL=../../../../true */